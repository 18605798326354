import React from "react";
import {Input, Table, Popconfirm} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';

const IngredientList = ({setIngredients, ingredients}) => {
    const removeIngredient = (ingredientId) => {
        const updatedIngredients = ingredients.filter(item => item.id !== ingredientId);

        setIngredients(updatedIngredients);
    };


    const handleIngredientAmountChange = (event, ingredientId) => {
        const newAmount = +event.target.value;

        const updatedIngredients = ingredients.map(item =>
            item.id === ingredientId ? {
                    ...item,
                    amount: newAmount,
                    pivot_energy: (item.energy / 100) * newAmount
                } : item
        );

        setIngredients(updatedIngredients);
    };

    const calculateMacros = (ingredient, macro) => {
        if(ingredient.energy === 0) {
            return 0;
        }

        return +((ingredient.pivot_energy / ingredient.energy) * macro).toFixed(2);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'original_name',
            key: 'name',
            render: (name, record) => <a target="_blank" href={"/ingredient/" + record.id}>{name}</a>,
        },
        {
            title: 'Kogus',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount, record) => (
                <Input
                    defaultValue={Math.round((record.pivot_energy / record.energy) * 100)}
                    onChange={e => handleIngredientAmountChange(e, record.id)}
                />
            ),
        },
        {
            title: 'Energia',
            dataIndex: 'pivot_energy',
            key: 'pivot_energy',
            render: (energy) => energy.toFixed(2),
        },
        {
            title: 'Süsivesikud',
            dataIndex: 'carbohydrate',
            key: 'carbohydrate',
            render: (carbohydrate, record) => calculateMacros(record, carbohydrate),
        },
        {
            title: 'Valgud',
            dataIndex: 'protein',
            key: 'protein',
            render: (protein, record) => calculateMacros(record, protein),
        },
        {
            title: 'Rasvad',
            dataIndex: 'fat',
            key: 'fat',
            render: (fat, record) => calculateMacros(record, fat),
        },
        {
            title: 'Kiudained',
            dataIndex: 'fiber',
            key: 'fiber',
            render: (fiber, record) => calculateMacros(record, fiber),
        },
        {
            title: '',
            key: 'actions',
            render: (_, record) => (
                <Popconfirm
                    title={`Kustuta ${record.original_name}?`}
                    onConfirm={() => removeIngredient(record.id)}
                    okText="Jah"
                    cancelText="Ei"
                >
                    <DeleteOutlined style={{color: "red"}}/>
                </Popconfirm>
            ),
        },
    ];

    const summaryData = ingredients.reduce((totals, ingredient) => {
        const amount = (ingredient.pivot_energy / ingredient.energy) * 100;
        totals.totalAmount += amount;
        totals.totalEnergy += ingredient.pivot_energy;
        totals.totalCarbohydrate += calculateMacros(ingredient, ingredient.carbohydrate);
        totals.totalProtein += calculateMacros(ingredient, ingredient.protein);
        totals.totalFat += calculateMacros(ingredient, ingredient.fat);
        totals.totalFiber += calculateMacros(ingredient, ingredient.fiber);
        return totals;
    }, {
        totalAmount: 0,
        totalEnergy: 0,
        totalCarbohydrate: 0,
        totalProtein: 0,
        totalFat: 0,
        totalFiber: 0,
    });


    return (
        <Table
            columns={columns}
            dataSource={ingredients}
            pagination={false}
            summary={() => (
                <Table.Summary.Row>
                    <Table.Summary.Cell><b>Kokku</b></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell><strong>{summaryData.totalAmount.toFixed(0)}g</strong></Table.Summary.Cell>
                    <Table.Summary.Cell>
                        <span style={{fontWeight: 'bold', color: summaryData.totalEnergy > 375 ? "red" : "limegreen"}}>
                            {summaryData.totalEnergy.toFixed(0)} kcal
                        </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                        <strong>{Math.round(summaryData.totalCarbohydrate)}g</strong> ({summaryData.totalEnergy > 0 ? Math.round((summaryData.totalCarbohydrate * 4 * 100) / summaryData.totalEnergy) : 0}%)
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                        <strong>{Math.round(summaryData.totalProtein)}g</strong> ({summaryData.totalEnergy > 0 ? Math.round((summaryData.totalProtein * 4 * 100) / summaryData.totalEnergy) : 0}%)
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                        <strong>{Math.round(summaryData.totalFat)}g</strong> ({summaryData.totalEnergy > 0 ? Math.round((summaryData.totalFat * 9 * 100) / summaryData.totalEnergy) : 0}%)
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                        <strong>{Math.round(summaryData.totalFiber)}g</strong>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            )}
        />
    );
};

export default IngredientList;
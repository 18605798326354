import React, {useEffect, useState} from "react";
import {Layout, Breadcrumb, List, Skeleton, Row, Col, Table} from 'antd';
import Api from "../api.js";
import {useParams} from "react-router-dom"
import GoogleAuthSetup from "../components/2-fa-link";

const {Content} = Layout;

const Settings = () => {
    const params = useParams();
    const [user, setUser] = useState('');

    useEffect(() => {
        Api.get('/v1/user').then(res => {
            setUser(res.data.data);
        })
    }, [])

    // Transforming the data into key-value pairs
    const tableData = Object.entries(user).map(([key, value]) => ({
        key, // Key name
        value: typeof value === 'boolean' ? value.toString() : (typeof value === 'object' ? JSON.stringify(value) : value) // Convert booleans to strings
    }));

    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
    ];

    console.log(user);

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Settings</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
                <Col span={24}>
                    <div className="site-layout-card">
                        <h1>Admin settings</h1>


                        {!user ? (
                            <Skeleton title={true} loading={true} active/>
                        ) : (
                            <>
                                <GoogleAuthSetup user={user}/>
                                <Table
                                    size="small"
                                    pagination={false}
                                    columns={columns}
                                    dataSource={tableData}
                                    rowKey="key" // Unique key for each row
                                />
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </Content>
    );
}

export default Settings;
import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    List,
    Row,
    Col,
    Button,
    Space,
    message,
    Tabs,
    Divider,
    Select,
    Typography,
    DatePicker, Tag, Table, Statistic, Avatar, Checkbox
} from 'antd';
import Api from "../api.js";
import {Link, useParams} from "react-router-dom"
import axios from "axios";
import {config, getCookie} from "../helpers";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip as ChartTooltip,
    XAxis,
    YAxis,
} from "recharts";
import moment from "moment";
import {fromJS} from 'immutable';
import dayjs from "dayjs";
import {CheckCircleOutlined, CopyOutlined, EditOutlined} from "@ant-design/icons";
import InvoiceModal from "../components/invoice-modal";
import InvoicesTable from "../components/invoices-table";

const {Title} = Typography;
const {TabPane} = Tabs;
const {Content} = Layout;
const {Option} = Select;
const {Text} = Typography;

const columnsData = [
    {
        title: 'Field',
        dataIndex: 'key',
        key: 'key',
        width: 300,
    },
    {
        title: 'value',
        dataIndex: 'value',
        key: 'value',
        render: (text, record) => {
            if (record.key === 'access' || record.key === 'invoice_payments' || record.key === 'invoices') {
                return false;
            }

            if (record.value === true) {
                return "true";
            }

            return (record.value === 'null' || record.value === 'false') ?
                <div style={{color: "#cfcfcf"}}>{record.value}</div> : record.value;
        }
    }
]

const User = () => {
    const params = useParams();
    const [user, setUser] = useState('');
    const [userToken, setUserToken] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const [allergens, setAllergens] = useState([]);

    useEffect(() => {
        const type = isNaN(params.id) ? 'email' : 'number';

        Api.get('/admin/allergens').then(response => {
            setAllergens(response.data.data);
        })

        Api.get('/admin/users' + (type === 'email' ? '?email=' + params.id : '/' + params.id)).then(res => {
            setUser(res.data.data);

            let data = [];

            for (const [key, value] of Object.entries(res.data.data)) {
                if (key === 'body_sizes' || key === 'intolerances' || key === 'allergens' || key === 'tdee') {
                    continue;
                }


                data.push({
                    key: key,
                    value: getValue(key, value)
                })
            }

            setDataSource(data);
        })
    }, []);

    const getValue = (key, value) => {
        if (key === 'gender') {
            return value === 1 ? "male" : "female";
        }

        if (key === 'goal') {
            return value === 1 ? 'lose weight' : value === 2 ? "maintain weight" : "gain weight";
        }

        if (key === 'unit_system') {
            return value === 1 ? "metric" : "imperial"
        }

        return value ? value : "null";
    }

    const getUserToken = () => {
        Api.get('/admin/users/' + user.user_id + '/token').then(res => {
            setUserToken(res.data.access_token);
        })
    }

    const copyText = () => {
        message.success('Token copied!');

        navigator.clipboard.writeText(userToken);
    }

    let tdeeData = [];
    let bodySizesData = [];
    let allergensData = [];
    let intolerancesData = [];
    let activityData = [];

    if (user) {
        for (const [key, value] of Object.entries(user.body_sizes)) {
            bodySizesData.push({
                key: key,
                value: getValue(key, value)
            })
        }

        for (const [key, value] of Object.entries(user.tdee)) {
            tdeeData.push({
                key: key,
                value: getValue(key, value)
            })
        }

        allergens.map(allergen => {
            allergensData.push({
                key: allergen.title,
                value: getValue(allergen.title, user.allergens.includes(allergen.id) ? 'true' : 'false')
            })
        })

        for (const [key, value] of Object.entries(user.intolerances)) {
            intolerancesData.push({
                key: key,
                value: getValue(key, value)
            })
        }

        if (user.activity_tracker) {
            for (const [key, value] of Object.entries(user.activity_tracker)) {
                activityData.push({
                    key: key,
                    value: getValue(key, value),
                })
            }
        }
    }


    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item><Link to={"/users"}>User</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>

            <Space direction={"vertical"} style={{overflow: "hidden", display: "block"}}>
                <Button onClick={getUserToken}>Get Access Token</Button>
                {userToken && (
                    <Text code style={{cursor: "pointer"}} onClick={copyText}>{userToken}</Text>
                )}
            </Space>

            <Row>
                <Col span={24}>
                    <div className="site-layout-card">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="User Info" key="uinfo">
                                <Divider orientation="left">
                                    <Title style={{margin: 0}} level={4}>User</Title>
                                </Divider>

                                <Table size="small" loading={!user} pagination={false} dataSource={dataSource}
                                       columns={columnsData}/>

                                <Divider orientation="left">
                                    <Title style={{margin: 0}} level={4}>Tdee</Title>
                                </Divider>

                                <Table size="small" loading={!user} pagination={false} dataSource={tdeeData}
                                       columns={columnsData}/>

                                <Divider orientation="left">
                                    <Title style={{margin: 0}} level={4}>Allergens</Title>
                                </Divider>

                                <Table size="small" loading={!user} pagination={false} dataSource={allergensData}
                                       columns={columnsData}/>

                                <Divider orientation="left">
                                    <Title style={{margin: 0}} level={4}>Intolerances</Title>
                                </Divider>

                                <Table size="small" loading={!user} pagination={false} dataSource={intolerancesData}
                                       columns={columnsData}/>

                                <Divider orientation="left">
                                    <Title style={{margin: 0}} level={4}>Body sizes</Title>
                                </Divider>

                                <Table size="small" loading={!user} pagination={false} dataSource={bodySizesData}
                                       columns={columnsData}/>

                                <Divider orientation="left">
                                    <Title style={{margin: 0}} level={4}>Activity tracker</Title>
                                </Divider>

                                <Table size="small" loading={!user} pagination={false} dataSource={activityData}
                                       columns={columnsData}/>
                            </TabPane>

                            <TabPane disabled={!user} tab="Access" key="access">
                                <UserAccess user={user} userToken={userToken}/>
                            </TabPane>

                            <TabPane disabled={!user} tab="Invoices" key="invoices">
                                <UserInvoices user={user} userToken={userToken}/>
                            </TabPane>

                            <TabPane disabled={!user} tab="Events" key="user_events">
                                <UserEvents user={user}/>
                            </TabPane>

                            <TabPane disabled={!userToken} tab="Weight log" key="weight-log">
                                <UserWeightTab user={user} userToken={userToken}/>
                            </TabPane>

                            <TabPane disabled={!userToken} tab="Diary" key="diary">
                                <UserDiary user={user} userToken={userToken}/>
                            </TabPane>

                            <TabPane disabled={!userToken} tab="Wellness" key="wellness">
                                <UserWellness user={user} userToken={userToken}/>
                            </TabPane>
                        </Tabs>
                    </div>
                </Col>
            </Row>

        </Content>
    );
}

const UserAccess = ({user, userToken}) => {
    const [date, setDate] = useState(dayjs());
    const [access, setAccess] = useState([...user.access].reverse());

    return (
        <div>
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>User access</Title>
            </Divider>

            {access && (
                <div>
                    <Table size="small" loading={!user} pagination={false} dataSource={access} columns={[
                        {
                            title: 'access_id',
                            dataIndex: 'access_id',
                            key: 'access_id',
                        },
                        {
                            title: 'product_id',
                            dataIndex: 'product_id',
                            key: 'product_id',
                        },
                        {
                            title: 'begin_date',
                            dataIndex: 'begin_date',
                            key: 'begin_date',
                        },
                        {
                            title: 'expire_date',
                            dataIndex: 'expire_date',
                            key: 'expire_date',
                        },
                        {
                            title: 'comment',
                            dataIndex: 'comment',
                            key: 'comment',
                        },
                        {
                            title: 'status',
                            key: 'status',
                            render: (text, record) => {
                                const isActive = () => {
                                    const currentDate = new Date();

                                    const beginDate = new Date(record.begin_date);
                                    const expireDate = new Date(record.expire_date);

                                    return currentDate >= beginDate && currentDate <= expireDate
                                }
                                return (
                                    <div>
                                        {isActive() ?
                                            <Tag color="green">Active</Tag> :
                                            (
                                                <Tag color="red">Inactive</Tag>
                                            )
                                        }
                                    </div>
                                )
                            },
                            width: 30,
                        }
                    ]}/>

                </div>
            )}

            <UserApplicationPaymentsTab user={user}/>
        </div>
    )
}

const UserInvoices = ({user, userToken}) => {
    // State to manage whether to show free invoices
    const [showFree, setShowFree] = useState(true);

    // Filter invoices based on the state of showFree
    const filteredInvoices = showFree
        ? [...user.invoices] // Show all invoices if the toggle is on
        : user.invoices.filter(invoice => invoice.paysys_id !== "free");

    return (
        <div>
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>Invoices</Title>
            </Divider>

            <Row>
                <Checkbox
                    checked={showFree}
                    onChange={e => setShowFree(e.target.checked)}
                >
                    Show Free Invoices
                </Checkbox>
            </Row>

            <br/>

            {user.invoices && (
                <InvoicesTable invoices={filteredInvoices.reverse()}/>
            )}
        </div>
    )
}


const UserDiary = ({user, userToken}) => {
    const [date, setDate] = useState(dayjs());
    const [diary, setDiary] = useState('');

    useEffect(() => {
        requestUserData(userToken, 'v2/diary?date=' + moment(date).format('YYYY-MM-DD'))
    }, [date])

    const requestUserData = (token, slug) => {
        const apiUrl = getCookie('api_url') ? getCookie('api_url') : config('api.url');

        axios.get(apiUrl + slug, {headers: {'Authorization': `Bearer ${token}`}}).then(response => {
            // Handle the response data
            setDiary(response.data.data);
        }).catch(error => {
            // Handle errors
            console.error(error);
        });
    }


    const onDateChange = (value, dateString) => {
        if (!dateString) {
            return false;
        }

        setDate(dayjs(value).format('YYYY-MM-DD'));
    };

    return (
        <div>
            <DatePicker format={['DD.MM.YYYY', 'YY-MM-DD']}
                        onChange={onDateChange}/>

            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>{moment(date).format("dddd, Do MMM")}</Title>
            </Divider>

            {diary && (
                <List
                    dataSource={diary[0].entries}
                    renderItem={item => {
                        return (
                            <List.Item>
                                <strong>{item.amount} g</strong> {item.ingredients.original_name}
                                <Tag>{Math.round(item.amount * item.ingredients.energy / 100)} kcal</Tag> {item.eaten ?
                                <Tag color={"green"}>Eaten</Tag> : ""} {item.recipe_id ?
                                <Tag color={"red"}>ID: {item.recipe_id}</Tag> : ""}
                            </List.Item>
                        )
                    }}
                />
            )}
        </div>
    )
}

const UserWeightTab = ({user, userToken}) => {
    const [weightsData, setWeightsData] = useState([]);

    useEffect(() => {
        requestUserData(userToken, 'v1/user/weight-logs?all=true')
    }, [])

    const graphData = () => {
        if (weightsData.length > 0) {
            let data = fromJS(weightsData).reverse().toJS();

            // Set min and max values from graph
            let max = Math.max.apply(
                Math,
                data.map((o) => {
                    return o.weight;
                })
            );

            let min = Math.min.apply(
                Math,
                data.map((o) => {
                    return o.weight;
                })
            );

            // Convert date to time in order to display correctly on graph x axis type number
            data = data.map((weight) => {
                let date = weight["date"];
                weight["date"] = new Date(date).getTime();
                return weight;
            });

            return {
                weights: data,
                min,
                max,
                min_date: data[0].date,
            };
        }

        return {
            weights: [],
            min: 0,
            min_date: 0,
            max: "auto",
        };
    };

    const getTicks = (min, max) => {
        let list = [];

        for (let i = min; i <= max; i = i + 2) {
            list.push(i);
        }

        return list;
    };

    const formatXAxis = (tickItem) => {
        return moment(tickItem).format('DD.MM.YY')
    };

    const requestUserData = (token, slug) => {
        const apiUrl = getCookie('api_url') ? getCookie('api_url') : config('api.url');

        axios.get(apiUrl + slug, {headers: {'Authorization': `Bearer ${token}`}}).then(response => {
            // Handle the response data
            setWeightsData(response.data.data);
        }).catch(error => {
            // Handle errors
            console.error(error);
        });
    }

    let data = graphData();
    let weightLog = data.weights;
    let max = data.max;
    let min = data.min;

    return (
        <div className="goal-graph">
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>Weight graph</Title>
            </Divider>

            <ResponsiveContainer className="goal-graph-container" width='100%'
                                 height={300}>
                <LineChart data={weightLog}>
                    <XAxis type="number" dataKey="date" tickFormatter={formatXAxis}
                           domain={[data.min_date, '']}/>
                    <YAxis width={30} ticks={getTicks(min, max)} type="number" domain={[min, max]}/>
                    <ChartTooltip wrapperStyle={{outline: "none"}}
                                  labelFormatter={(value) => (formatXAxis(new Date(value)))}/>
                    <CartesianGrid stroke="#f5f5f5"/>
                    <Line type="monotone" name={'Weight'}
                          dot={{r: 2, stroke: 'none', fill: '#19AEF0'}} dataKey="weight" stroke="#19AEF0"
                          yAxisId={0} strokeWidth={1}/>
                    <Line type="monotone" strokeDasharray="3 3" dot={{strokeDasharray: 0, r: 3}}
                          name={'goal'} connectNulls dataKey="goal" stroke="#0EB8AE"
                          strokeWidth={2}/>
                    <Legend verticalAlign="bottom"/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}


const UserApplicationPaymentsTab = ({user}) => {
    const [payments, setPayments] = useState([]);

    useEffect(() => {
        getPayments()
    }, [])

    const getPayments = () => {

        Api.get('/admin/users/' + user.user_id + '/application_payments').then(response => {
            setPayments(response.data.data);
        })

    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: 'Product ID',
            dataIndex: 'product_id',
            key: 'product_id',
        },
        {
            title: 'Purchase Date',
            dataIndex: 'purchase_date',
            key: 'purchase_date',
        },
        {
            title: 'Expires Date',
            dataIndex: 'expires_date',
            key: 'expires_date',
        },
        {
            title: 'Status',
            dataIndex: 'product_id',
            key: 'product_id',
            width: 30,
            render: (record) => <span>{record.is_cancelled === 1 ? "Cancelled" : "Active"}</span>,
        },
        {
            title: '',
            dataIndex: 'latest_receipt',
            key: 'latest_receipt',
            width: 30,
            render: (record) => <Button type="primary" shape="round" icon={<CopyOutlined/>} onClick={() => {
                navigator.clipboard.writeText(record)
            }}>Copy receipt</Button>,
        },
    ];

    return (
        <div>
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>Itunes/Apple</Title>
            </Divider>

            {payments.length > 0 && (
                <ResponsiveContainer width='100%'
                                     height={300}>
                    <Table columns={columns} dataSource={payments} pagination={false}/>
                </ResponsiveContainer>
            )}
        </div>
    )
}

const UserEvents = ({user}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Api.get('/admin/user/events/' + user.user_id).then(res => {
            setData(res.data.data);
            setLoading(false);
        }).catch(error => {
            console.error("Error fetching data:", error);
        });
    }, [])

    return (
        <>
            <Row type="flex" justify="space-between" align="middle">
                <h1>Events</h1>
            </Row>

            <div>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    loading={loading}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src={item.user.avatar}/>}
                                title={<div><Link
                                    to={'/user/' + item.user.user_id + '?tab=events'}>{item.user.name_f}</Link> <Tag
                                    color="blue">{item.event}</Tag></div>}
                                description={moment(item.created_at).format('DD.MM.YYYY HH:mm:ss')}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </>
    )
}

const UserWellness = ({user, userToken}) => {
    const [loading, setLoading] = useState(false);
    const [wellnessLog, setWellnessLog] = useState('');

    useEffect(() => {
        requestUserData(userToken, 'v2/user/wellness?start_date=2022-01-01')
    }, [])

    const requestUserData = (token, slug) => {
        const apiUrl = getCookie('api_url') ? getCookie('api_url') : config('api.url');

        setLoading(true);

        axios.get(apiUrl + slug, {headers: {'Authorization': `Bearer ${token}`}}).then(response => {
            // Handle the response data
            setWellnessLog(response.data.data.reverse());
            setLoading(false);
        }).catch(error => {
            // Handle errors
            console.error(error);
        });
    }

    const renderColumnTag = (value) => {
        if (value < 4) {
            return (<Tag color="red">{value}</Tag>)
        }

        if (value <= 7) {
            return (<Tag color="orange">{value}</Tag>)
        }

        return (<Tag color="green">{value}</Tag>)
    }


    return (
        <div>
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>Wellness log</Title>
            </Divider>

            <Table size="small" loading={loading}
                   pagination={false}
                   columns={[
                       {title: 'id', dataIndex: 'id', key: 'id', width: 80},
                       {title: 'date', dataIndex: 'date', key: 'date', width: 140},
                       {
                           title: 'user_id',
                           dataIndex: 'user_id',
                           key: 'user_id',
                           render: (value, record) => <Link to={"/user/" + value}>{value}</Link>
                       },
                       {
                           title: 'sleep',
                           dataIndex: 'sleep',
                           key: 'sleep',
                           render: (value, record) => renderColumnTag(value)
                       },
                       {
                           title: 'health',
                           dataIndex: 'health',
                           key: 'health',
                           render: (value, record) => renderColumnTag(value)
                       },
                       {
                           title: 'energy',
                           dataIndex: 'energy',
                           key: 'energy',
                           render: (value, record) => renderColumnTag(value)
                       },
                       {title: 'eaten', dataIndex: 'eaten', key: 'eaten', render: (value, record) => renderColumnTag(value)},
                   ]} dataSource={wellnessLog}
            />
        </div>
    )
}


export default User;
import React, {useEffect, useState} from "react";
import {Layout, Breadcrumb, List, Typography, Row, Col, Input, Button, Space, Form, Table, Tag} from 'antd';
import Api from "../api.js";
import {Link, useNavigate, useParams} from "react-router-dom"
import moment from "moment/moment";

const {Content} = Layout;
const {Text} = Typography;
const {Search} = Input;

const User = () => {
    const params = useParams();
    const [user, setUser] = useState('');
    const [users, setUsers] = useState([]);
    const [meta, setMeta] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [loading, setLoading] = useState(false);
    const [visibleRowKey, setVisibleRowKey] = useState(null);
    const navigate = useNavigate();

    // Function to handle toggle visibility
    const handleToggleVisibility = (key) => {
        setVisibleRowKey(visibleRowKey === key ? null : key);
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        searchUser();
    }, [page])

    const searchUser = () => {
        let query = user ? '&search=' + user : '';

        setLoading(true);

        Api.get('/admin/user-list?page=' + page + query).then(res => {
            setMeta(res.data.meta);
            setUsers(res.data.data);
            setLoading(false);
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'user_id',
            key: 'user_id',
            width: 60,
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            render: (value, record) => (<div><Link to={'/user/' + record.user_id}>{value}</Link></div>)
        },
        {
            title: 'name_f',
            dataIndex: 'name_f',
            key: 'name_f',
        },
        {
            title: 'added',
            dataIndex: 'added',
            key: 'added',
            render: (value, record) => (<div>{moment(new Date(value)).format('DD.MM.YYYY - HH:mm')}</div>)
        },
        {
            title: 'account_type',
            dataIndex: 'type',
            key: 'type',
            render: (value, record) => {
                if (record.facebook_id) {
                    return <Tag>Facebook</Tag>
                }

                if (record.google_id) {
                    return <Tag>Google</Tag>
                }

                if (record.apple_id) {
                    return <Tag>Google</Tag>
                }
            }
        },
        {
            title: 'access',
            dataIndex: 'access',
            key: 'access',
            render: (value, record) => {
                const isActive = () => {
                    const currentDate = new Date();

                    if (record.access) {
                        // Use some to check if any record meets the criteria
                        return record.access.some(val => {
                            // Expired product
                            if (val.product_id === 6) {
                                return false;
                            }

                            const beginDate = new Date(val.begin_date);
                            const expireDate = new Date(val.expire_date);

                            // Check if the current date is within the begin_date and expire_date range
                            return currentDate >= beginDate && currentDate <= expireDate;
                        });
                    }
                };

                return (
                    <div>
                        {isActive() ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}
                    </div>
                )
            }
        }
    ]

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>User</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <Row justify="space-between" align="middle">

                    <h1>Users ({meta ? meta.total : 0})</h1>

                    <Search placeholder="Search user" onChange={e => setUser(e.target.value)} onSearch={() => {
                        setPage(1);
                        searchUser();
                    }}
                            style={{width: 300}}/>
                </Row>

                <Table loading={loading} style={{width: "100%"}} onChange={e => setPage(e.current)}
                       size="small"
                       pagination={{pageSize: pageSize, total: meta.total, current: meta.current_page}}
                       columns={columns} dataSource={users}/>
            </div>
        </Content>
    );
}

export default User;
import React, {useState} from 'react';
import {Button, Checkbox, Divider, Form, Input, message, Row, Space} from 'antd';
import Api from "../api";
import {setCookie} from "../helpers";

const Login = ({user, setUser, setHasAccess}) => {
    const [googleAuthId, setGoogleAuthId] = useState('');
    const [authNumber, setAuthNumber] = useState('');

    const onFinish = (values) => {
        Api.authEmail(values.email, values.password).then((res) => {
            message.success('Login successful');

            setCookie('fl_admin_access_token', res.data.access_token, 1);

            window.location.reload();
        }).catch(err => {
            if (err.response.data.message === '2FA required for user') {
                setGoogleAuthId(err.response.data.data.user_id)
            } else {
                message.error('Invalid login!');
            }
        })
    };

    const verifyAuth = () => {
        Api.authVerify(googleAuthId, authNumber).then(res => {
            message.success('Login successful');

            setCookie('fl_admin_access_token', res.data.data.accessToken, 1);

            window.location.reload();
        })
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Row type="flex" justify="center" align="middle" style={{height: "100vh", overflow: "hidden"}}>
            <Space direction="vertical">
                <h1 style={{textAlign: "center", color: "white"}}>{googleAuthId ? "2FA Auth" : "Login"}</h1>

                {googleAuthId ? (
                    <>
                        <p style={{color: "white"}}>Enter number in authenticator:</p>
                        <Input
                            size="large"
                            style={{ width: "200px" }}
                            onChange={(e) => setAuthNumber(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && verifyAuth()}
                            placeholder="123456"
                            autoFocus
                        />
                        <div style={{textAlign: "center", display: "flex", gap: "8px"}}>
                            <Button size="large" type="default" onClick={() => setGoogleAuthId('')}>Go back</Button>
                            <Button size="large" type="primary" onClick={verifyAuth}>Verify code</Button>
                        </div>
                    </>
                ) : (

                    <Form
                        name="basic"
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        initialValues={{remember: false}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        size="large"
                    >
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input size="large"/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password size="large"/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block size="large">
                                Login
                            </Button>
                            <Divider orientation="center">
                                Or
                            </Divider>
                            <a href="https://app.fitlap.ee">
                                <Button type="default" block size="large">
                                    Fitlap app
                                </Button>
                            </a>
                        </Form.Item>
                    </Form>
                )}
            </Space>
        </Row>
    )
};

export default Login;
import React, { useState } from 'react';
import Api from "../api";
import {Button, Input, InputNumber, message, Space} from "antd";  // Library for rendering QR code

const GoogleAuthSetup = ({user}) => {
    const [secret, setSecret] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [authNumber, setAuthNumber] = useState('');
    const [authEnabled, setAuthEnabled] = useState(user.google2fa_enabled);

    const generateSecret = () => {
        Api.post("/v1/user/auth/2fa").then(res => {
            setSecret(res.data.secret);
            setQrCode(res.data.qr_code);
        })
    };

    const linkAccount = async () => {
        Api.post("/auth/2fa/verify", {
            "user_id": user.user_id,
            "2fa_code": authNumber
        }).then(res => {
            setAuthEnabled(true);

            return message.success('2FA Linked!');
        })
    };

    if (authEnabled) {
        return false;
    }

    return (
        <div style={{marginBottom: 20}}>
            {qrCode ? (
                <div>
                    <h2>Scan this QR code with Google Authenticator</h2>

                    <Space direction="vertical">
                        <img src={qrCode}/>
                        <span>Enter number in authenticator:</span>
                        <Input onChange={(e) => setAuthNumber(e.target.value)} placeholder="123456"/>
                        <Button type="primary" onClick={linkAccount}>Link Account</Button>
                    </Space>
                </div>
            ) : (
                <Button type="primary" onClick={generateSecret}>Setup 2FA</Button>
            )}
        </div>
    );
}

export default GoogleAuthSetup;
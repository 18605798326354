import axios from 'axios';
import {message} from 'antd'

import {getCookie, config} from './helpers.js';

const ROOT_URL = getCookie('api_url') ? getCookie('api_url') : config('api.url');
const token = getCookie('fl_admin_access_token');

const api = axios.create({
    baseURL: ROOT_URL
});

// Set Headers for Request
api.interceptors.request.use(config => {
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers['X-Auth-Token'] = `4sC6KWJisLkOkFQCW5xU`;
    }

    // Remove Authorization for login
    if (isAuthRequest(config)) {
        delete config.headers.Authorization;
    }

    config.meta = config.meta || {}
    config.meta.requestStartedAt = new Date().getTime();

    return config;
}, error => Promise.reject(error));

function statsResponseHandler(response) {
    return response;
}

function errorResponseHandler(error) {
    message.error(error.response.data.message);

    return Promise.reject(error);
}

// Global error handling for all responses
api.interceptors.response.use(
    statsResponseHandler,
    errorResponseHandler
);

function isAuthRequest(config) {
    return !!(config.url.includes('/auth/token') || config.url.includes('/auth/facebook'));
}

/*
 * Exports
 */
export default class Api {
    static auth(userToken = null) {
        return axios({
            method: "get",
            url: "/v1/user",
            baseURL: "https://api.fitlap.ee",
            headers: {
                'Authorization': `Bearer ${userToken ? userToken : token}`
            },
        });
    }

    static authEmail(username, password) {
        return axios.post('https://api.fitlap.ee/admin/auth/token', {'username': username, 'password': password});
    }

    static authVerify(googleAuthId, authNumber) {
        return axios.post('https://api.fitlap.ee/auth/2fa/verify', {'user_id': googleAuthId, '2fa_code': authNumber});
    }

    static get(url) {
        return api.get(url);
    }

    static post(url, data = null) {
        return api.post(url, data);
    }

    static put(url, data = null) {
        return api.put(url, data);
    }

    static delete(url, data = null) {
        return api.delete(url, {data: data});
    }
}

import React, {useState} from "react";
import {Table, Input} from 'antd';
import Api from "../api";

const {Search} = Input;

const IngredientSearch = ({searchResponse, setSearchResponse, onClickEvent, onlyId}) => {
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const onSearch = (value) => {
        setLoading(true);

        if (value.length > 2) {
            Api.post('admin/ingredients/search', {key: value}).then(res => {
                setSearchResponse(res.data)
                setLoading(false);
            })
        } else {
            setSearchResponse([])
            setLoading(false);
        }
    }

    const handleRowClick = (record) => {
        onClickEvent(onlyId ? record.id : record);
        setSearchValue("");  // Clear the search input value
        setSearchResponse([]); // Clear the search results
    };

    return (
        <div>
            <Search
                placeholder={"Otsimiseks kirjuta vähemalt 2 tähte"}
                enterButton="Search"
                allowClear
                size="large"
                loading={loading}
                onSearch={onSearch}
                value={searchValue}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                    onSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                    e.key === 'Enter' && e.preventDefault()
                }}
            />

            {searchResponse.length > 0 && (
                <Table loading={loading} dataSource={searchResponse} pagination={false} showHeader={false}
                       scroll={{x: false, y: 350}} style={{cursor: "pointer"}} onRow={(record) => {
                    return {
                        onClick: () => handleRowClick(record)
                    }
                }}>
                    <Table.Column key="key" dataIndex="id" width={80}/>
                    <Table.Column key="search" dataIndex="original_name"/>
                </Table>
            )}
        </div>

    );
};

export default IngredientSearch;